import styled from "styled-components";

import PlusIcon from "Icons/PlusIcon";

import MenuLink from "../UserMenu/MenuLink";

export const Layout = styled.div`
  display: inline-block;
  position: relative;
  display: none;

  @media (min-width: 765px) {
    display: inline-block;
  }
  button {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: transparent;
    border: none;
    color: var(
      --mode-overrides-header-text,
      var(--button-snow-color, var(--snow))
    );
    display: flex;
    align-items: center;
    font-size: inherit;
    padding: 0 35px 0 8px;
    white-space: nowrap;
    cursor: pointer;
    img {
      max-width: 24px;
      max-height: 24px;
      border-radius: 2px;
      overflow: hidden;
    }
    span.chevron {
      position: absolute;
      right: 3px;
      top: 6px;
      height: 24px;
      width: 24px;
    }
    &.picture {
      padding: 0 35px 0 3px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      outline: none;
    }
  }
  a {
    text-decoration: none;
    color: var(--section-ebony-light-color, var(--ebony-light));
    font-size: 15px;
  }

  .menu-dropdown {
    background-color: var(--menu-snow-background-color, var(--snow));
    padding: 8px;
    width: 214px;
    min-width: 300px;
    box-sizing: border-box;
    left: auto;
    right: 0;
    overflow: hidden;
    margin-top: 8px;
    a {
      &:hover,
      &:focus,
      &:active {
        background-color: var(
          --link-color-primary-100-background-color,
          var(--color-primary-100)
        );
        border: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        box-shadow: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        margin: ${props => (props.theme.name === "contrast" ? "0" : "inherit")};
        position: relative;
        span.active {
          position: absolute;
          top: 14px;
          left: 4px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background-color: var(
            --link-color-primary-600-background-color,
            var(--color-primary-600)
          );
        }
      }
    }
  }
`;

export const NewMenuLink = styled(MenuLink)`
  & div:last-child {
    margin-left: 8px;
  }
`;
export const OrganizationLabel = styled.div`
  color: var(--section-granite-color, var(--granite));
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export const Separator = styled.hr`
  width: calc(100% + 16px);
  margin-left: -8px;
  overflow: hidden;
  background: var(--section-periwinkle-grey-background, var(--periwinkle-grey));
`;

interface CreateOrgSeparatorProps {
  isTop?: boolean;
}
export const CreateOrgSeparator = styled.hr<CreateOrgSeparatorProps>`
  background: var(--section-periwinkle-grey-background, var(--periwinkle-grey));
  ${({ isTop }) => (isTop ? "auto" : "width: calc(100% + 16px)")};
  ${({ isTop }) => (isTop ? "auto" : "margin-left: -8px")};
  overflow: hidden;
  ${({ isTop }) => (isTop ? "margin-bottom:8px" : "margin-top:8px")};
`;

export const MenuSeparator = styled.hr`
  background: var(--section-periwinkle-grey-background, var(--periwinkle-grey));
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  overflow: hidden;
`;

export const CreateOrg = styled.div`
  &:hover,
  &:focus,
  &:active {
    background-color: var(
      --link-color-primary-100-background-color,
      var(--color-primary-100)
    );
  }
  display: flex;
  align-items: center;
`;

export const Plus = styled(PlusIcon)`
  g {
    fill: var(--mode-text-neutral-default);
  }
  padding-right: 8px;
`;

export const CreateOrgText = styled.span`
  color: var(--mode-text-neutral-default);
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  padding-left: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
  align-items: center;
`;

export const UserInfoImageContainer = styled.div`
  margin-right: 16px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  img {
    border-radius: 2px;
  }
`;

export const UserInfoTextContainer = styled.div`
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const EmailText = styled.p`
  color: var(--section-granite-color, var(--granite));
  font-family: "Open sans", sans-serif;
  font-weight: 600;
  line-height: 16px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 228px;
  margin-top: 6px;
`;

export const NameText = styled.p`
  color: var(--header-ebony-color, var(--ebony));
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 228px;
  margin-bottom: 2px;
`;

export const ThemeText = styled.p`
  color: var(--section-granite-color, var(--granite));
  font-family: "Open sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
  padding-left: 8px;
`;

export const ButtonThemeContainer = styled.div`
  display: flex;
  height: 24px;
  padding-left: 6px;
  margin-top: 8px;
  margin-bottom: 16px;
  align-items: center;
  justify-content: left;
`;
